import { Redirect } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import { NpmClubCardGetCardPage } from '../../local_modules'

import type { ClubCardGetCardPageSectionData } from '../../local_modules/types/ClubCard/ClubCardGetCard'
import type { PageProps } from 'gatsby'

interface ClubCardGetCardPageSection {
  cmsClubCardGetCardLp?: {
    sections?: [{ data?: ClubCardGetCardPageSectionData }]
  }
}

export default function ClubCardGetCardPage({
  data,
  location: { pathname },
}: PageProps<ClubCardGetCardPageSection>) {
  const clubCardGetCardPageData =
    data?.cmsClubCardGetCardLp?.sections?.[0]?.data

  if (!clubCardGetCardPageData || !clubCardGetCardPageData.visibility) {
    const params = new URLSearchParams({ from: encodeURIComponent(pathname) })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmClubCardGetCardPage data={clubCardGetCardPageData} />
}

export const querySSG = graphql`
  query ClubCardGetCardPageQuery {
    cmsClubCardGetCardLp {
      sections {
        data
      }
    }
  }
`
